@import "mixins.less";

// Reset and dependencies
@import "normalize.less";
@import "print.less";

// Core CSS
@import "scaffolding.less";
@import "type.less";
@import "grid.less";
@import "forms.less";

// Components
@import "component-animations.less";
@import 'buttons.less';
@import "navs.less";
@import "navbar.less";
@import "responsive-embed.less";
@import "alerts.less";

// Utility classes
@import "utilities.less";
@import "responsive-utilities.less";

// svg sprite
@import '../images/svg/css/sprite.css';
@import '../images/svg/svg.css';

// plugins
@import './__slick.less';
@import (less) './__magnific-popup.css';
@import (less) './__aos.css';

// own project
@import "project.less";