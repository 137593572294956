header {
	z-index: 1010;
	position: fixed;
	top:0px;
	left:0px;
	right:0px;
	height: 60px;
	padding: 0px 0px;
	background-color: @brand-second;
	#logo {
		float: left;
		background-image:url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2078.41%2069.001%22%3E%3Cpath%20fill%3D%22%236c962e%22%20d%3D%22M.001%200v44.341H0l39.205%2024.66%2039.205-24.66V0z%22%2F%3E%3C%2Fsvg%3E');
		background-repeat:no-repeat;
		background-size:100% auto;
		background-position: top center;
		display:block;
		height: 230px;
		padding: 50px 15px;
		margin-top: -20px;
		img {
			margin:0px auto;
		}
	}
	nav {
		ul.nav {
			float: right;
			li {
				a {
					color: #ffffff;
					text-transform: uppercase;
					font-size: (@font-size-base);
					font-weight: 500;
					line-height: (@font-size-base);

					padding: 20px 30px;
					&:focus {
						background-color: transparent;
					}
				}
				
				&:hover {
					 > a {
						.transition-duration(.2s);
						background-color: @brand-primary;
					}
				}

				&.active {
					a {
						font-weight: 600;
					}
				}

				&.facebook {
					a{
						padding: 0px 0px;
						img {
							height: 60px;
							width:60px;
							display:block;
						}
					}
					&:hover {
						a {
							background-color: #ffffff;
						}
					}
				}

				&.dropdown {
					> .dropdown-menu {
						position: absolute;
						background-color: @brand-primary;
						display: none;
						list-style:none;
						
						margin: 0px;
						padding: 10px 0px;

						min-width: 200px;
						max-width: 250px;
						width:100%;
						li {
							a {
								display:block;
								padding: 5px 30px;
								line-height: @font-size-base + 5px;
								font-weight: 300;
								.transition-duration(.2s);
								&:hover {
									text-decoration: none;
									color: @brand-second;
								}
							}
						}
					}

					&:hover {
						> .dropdown-menu {
							display: inline-block;
						}
					}
				}

				//@media (max-width: @screen-md-max) {
				//}
				//@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
				//}
			}
		}
	}
	@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
		#menu {
		}
		#logo {
			margin: 0px;
			padding: 10px 10px;
		}
		nav {
			ul.nav {
				> li { 
					a {
						padding: 20px 20px;
						text-align: center;
					}
				}
			}
		}
	}

	@media (max-width: @screen-xs-max) {
		height: auto;

		#logo {
			background-image:none;
			height: auto;
			padding: 5px;
			width: 135px;
			margin-top: 0px;
		}
		nav {
			ul.nav {
				margin-top: 10px;
				margin-bottom: 10px;
				float: none;
				li {
					text-align: center;

					a {
						padding: 12px 30px;
					}
					&.facebook {
						a {
							img {
								display:inline-block;
							}
						}
					}

					&.dropdown {
						> .dropdown-menu {
							position: relative;
							max-width: inherit;
							min-width: auto;
						}
					}
				}
			}
		}
	}
}

.navbar-toggle {
	margin-right: 0px;
	.icon-bar {
		background-color: #fff;
	}
}

.slider {
	&__items {
		height: 400px;
		overflow-y:hidden;
		.item {
			height: 400px;

			& > .image {
				width: 100%;
				height: 100%;
				background-repeat: no-repeat;
				background-position: center center;
				background-size: cover;

				& > .content {
					//.container;
					background-color:rgba(36, 73, 36, 0.5);
					height: 100%;
					position: relative;

					.content__inner {
						width: 100%;
						text-align: center;
						.vertical-align;

						h1 > span, h2 > span, p > span {
							color: #ffffff;
							font-size: 45px;
							font-weight: bold;
							padding: 10px;
							line-height: 1.9;
							@media (max-width: @screen-xs-max) {
								font-size: 30px;
								line-height:1.5;
							}
						}

						p > span {
							color: #ffffff;
							font-size: 30px;
							font-weight: bold;
							padding: 10px;
							line-height: 1.8;
							@media (max-width: @screen-xs-max) {
								font-size: 20px;
							}
						}

						@media (max-width: @screen-xs-max) {
							width:100%;
						}
					}
				}
			}

			&--campaign:after {
				content: "";
				background-image: url('../images/AKCE.png');
				background-repeat:no-repeat;
				background-position: center center;
				background-size: 100% 100%;
				width:100px;
				height:100px;
				
				z-index: 90;
				position: absolute;
				bottom: 60px;
				left: 50%;
				margin-left: -50px;
				@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
					right: -50px;
				}
				@media (max-width: @screen-xs-max) {
					right: -50px;
					top: -50px;
				}
			}
		}
	}


	.slick-dots {
		position: absolute;
		list-style: none;
		margin: 0px auto;
		padding: 0px;
		bottom: 30px;
		left: 0px;
		right: 0px;
		text-align:center;
		li {
			display: inline-block;
			button {
				border-radius: 50%;
				background-color:#ffffff;
				font-size: 0px;
				width: 20px;
				height: 20px;
				display: block;
				border: 0px;
				outline: none;
			}
			&.slick-active {
				button {
					background-color: @brand-primary;
					padding: 1px;
					border: 4px solid #ffffff;
				}
			}
		}
		li + li {
			margin-left:12px;
		}
	}
}

.fastNavigator {
	background-color:@brand-second;
	&__content {
		&__item {
			text-align: center;
			padding-top:20px;
			padding-bottom:20px;
			a {
				.image {
					margin-bottom:10px;
					height:130px;
					display:table-cell;
					vertical-align: bottom;
					img {
						display:inline-block;
					}
				}
				.title {
					display: block;
					text-transform: uppercase;
					color: #ffffff;
					padding: 20px 20px;
					line-height: 21px;
					strong {
						//color: @brand-green;
					}
				}
				&:hover {
					text-decoration: none;
				}

				@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
					.title {
						padding: 20px 10px;
					}
				}
				@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
					.title {
						padding: 20px 20px;
					}
				}
				@media (max-width: @screen-xs-max) {
					.image {
						height: auto;
					}
				}
			}

			@media (max-width: @screen-xs-max) {
				padding: 30px 0px;
			}
		}
	}
}

.menu3 {
	background-image:url('../images/menu.jpg');
	background-repeat: repeat-y;
	background-position: top center;
	background-size: cover;
	color: #ffffff;
	&__inner {

		padding: 30px 30px;
	}

	&__category {
		margin-bottom: 10px;

		h2 {
			color: @brand-green;
			background-image:url('../images/line.png');
			background-repeat: no-repeat;
			background-position: bottom left;
			background-size: 100% auto;
			padding-bottom:10px;
		}

		&__items {
			
		}

		&__item {
			.name {

			}
			.price {
				text-align: right;
			}
			@media (max-width: @screen-xs-max) {
				margin-bottom: 15px;
			}
		}
	}

	@media (max-width: @screen-xs-max) {
		background-size: 100% auto;
	}
}

.menu2 {
	&__inner {

		padding: 30px 30px;
	}

	&__item {
		margin-bottom: 30px;
		.image {
			padding-top:30px;
			img {
				margin: 0px auto;
			}
		}
		.content {
			h2 {
				color: @brand-green;
				small {
					display:block;
					font-size: (@font-size-base - 5px);
				}
			}
			.price {
				position: absolute;
				right: 15px;
				top: 60px;
				> .amount {
					font-weight: bold;
					font-size: @font-size-h3;
				}
			}
			p {
				text-align: justify;
				font-weight: 300;
			}

			@media (max-width: @screen-xs-max) {
				height: auto !important;
				.price {
					position: relative;
					width: 100%;
					right: auto;
					top: auto;
					text-align: right;
				}
			}
		}
	}
}

.menu {
	background-repeat:no-repeat;
	background-size: cover;
	background-position: top center;
	color: #ffffff;
	&__inner {
		background-image:url('../images/menu.jpg');
		background-repeat: repeat-y;
		background-position: top center;
		background-size: cover;
		
		@media (max-width: @screen-xs-max) {
			background-size: 100% auto;
		}
	}

	&__header {
		text-align: center;
		span {
			display:inline-block;
			color: #ffffff;
			font-size: @font-size-h3;
			padding: 18px 90px;
			text-transform: uppercase;
			background-image:url('../images/menu-header.png');
			background-position: top center;
			background-size: 100% auto;
			background-repeat: no-repeat;
		}
		@media (max-width: @screen-xs-max) {
			span {
				padding:6% 20px 18px 20px;
				width: 100%;
			}
		}
	}

	&__prices {
		padding: 20px 60px 10px 60px;
		text-align :center;
		span {
			font-size: (@font-size-base + 0px);
			&.bigger {
				font-size: @font-size-h2;
				//color: @brand-green;
			}
		}

		@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
			padding: 20px 20px;
		}

		@media (max-width: @screen-xs-max) {
			padding: 20px 20px;
			span.price {
				font-size: @font-size-base - 1px;
				>.bigger {
					font-size: @font-size-h4;
				}
			}
		}
	}

	&__content {
		padding: 20px 60px;

		@media (max-width: @screen-xs-max) {
			padding: 20px 20px;
		}
	}

	&__footer {
		text-align: center;
		padding-bottom: 20px;
		p {
			font-weight: 300;
			&.bigger {
				//color: @brand-green;
				font-size: @font-size-h1 + 30px;
			}
		}

		@media (max-width: @screen-xs-max) {
			padding: 5px 20px;
			p {
				&.bigger {
					font-size: @font-size-h1 + 5px;
				}
			}
		}
	}
}

.lunch {
	display:block;
	margin-bottom: 30px;
	padding-bottom: 30px;

	background-image:url('../images/line.png');
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: bottom center;
	position: relative;
	&:last-child {
		background-image: none;
	}
	&__inner {
		.left, .right {
			font-weight: 300;
			display:block;
			line-height: 20px;
			margin-bottom:15px;
		}
	}
	&__date {
		.date {
			text-align: center;
			display:block;
			font-size: @font-size-h1 + 60px;
			line-height: @font-size-h1 + 40px;
		}
		.day {
			background-color: #ffffff;
			color: @brand-second;
			text-transform: uppercase;
			font-weight: 500;
			display:block;
			text-align: center;
			line-height: @font-size-base + 1px;
			padding: 5px 10px 2px 10px;
		}
		@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
			margin-bottom: 20px;
			text-align: center;
			.day {
				display:inline-block;
			}
		}
		@media (max-width: @screen-xs-max) {
			margin-bottom: 20px;
		}
	}
	&--active {
		//color: @brand-green;
		.lunch__date {
			.day {
				background-color: @brand-green;
			}
		}
	}
	&--tip:before {
		content: "";
		background-image: url('../images/TIP.png');
		background-repeat:no-repeat;
		background-position: center center;
		background-size: 100% 100%;
		width:100px;
		height:100px;
		
		position: absolute;
		right: -110px;
		@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
			right: -50px;
		}
		@media (max-width: @screen-xs-max) {
			right: -50px;
			top: -50px;
		}
	}
}

.contact {
	position: relative;
	&__content {
		position: absolute;
		background-color: @brand-second;
		color: #ffffff;
		padding: 30px 200px 30px 100px;
		z-index:900;
		top:80px;
		display:block;

		.cbox {
			.clearfix;
			margin-bottom: 10px;
			&:last-child {
				margin-bottom: 0px;
			}
			.cicon {
				float: left;
				width: 20%;

				padding-top: 5px;
			}
			.ctext {
				float: right;
				width: 80%;
				
				p {
					margin: 0px;
					line-height: @font-size-base + 3px;
				}
			}
		}
		@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
			padding: 30px 50px 30px 50px;
		}

		@media (max-width: @screen-xs-max) {
			padding: 30px 10px;
			position: relative;
			top: 10px;
		}
	}
}

.content {
	&__header {
		height: 350px;
		width:100%;
		&__image {
			width: 100%;
			height: 100%;

			background-repeat: no-repeat;
			background-position: top center;
			background-size: cover;
		}
		&__content {
			//.container;
			background-color:rgba(36, 73, 36, 0.5);
			height: 100%;
			position: relative;

			&__inner {
				width: 100%;
				text-align: center;
				.vertical-align;

				h1 > span, h2 > span, p > span {
					color: #ffffff;
					font-size: 45px;
					font-weight: bold;
					padding: 10px;
					line-height: 1.9;
					@media (max-width: @screen-xs-max) {
						font-size: 30px;
						line-height:1.5;
					}
				}
			}
		}
	}
	&__text {
		padding-top:30px;
		padding-bottom:30px;
		&.inverse {
			background-color: @brand-second;
		}
	}
}

.news {
	&__item {
		margin-bottom: 30px;
		.image {
			margin-bottom: 10px;
		}
		.text {
			h2 {
				margin: 0px;
				color: @brand-primary;
			}
			p {
				text-align: justify;
				font-weight: 300;
			}
		}
	}
}

.text {
	&--layout1 {
		h2 {
			color: @brand-primary;
		}
		p {
			margin-bottom: 30px;
			line-height: 30px;
			font-weight: 300;
		}
	}
}

.campaign {
	background-color: @brand-primary;
}

footer {
	padding: 10px 0px;
	text-align: center;
	font-size: 14px;
	background-color: @brand-second;
	color: @gray-dark;
	a {
		display:inline-block;
	}
}

#map {
	height: 350px;
	width: 100%;
	display: block;
}

.text-bold {
	font-weight: bold;
}

//section {
//	margin-bottom: 55px;
//}

.vertical-align {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.w615 {
	width: 615px;
	.center-block;
	@media (max-width: @screen-xs-max) {
		width: 100%;
	}
}

.table {
	background-image: url('../images/table.png');
	background-repeat: no-repeat;
	background-position: top center;
	background-size: 100% auto;

	width: 300px;
	height: 407px;
	display: block;
	bottom: 50px;
	right: 20px;
	position: absolute;
	z-index:910;

	&__inner {
		background-repeat:no-repeat;
		background-size: auto 100%;
		background-position: top center;

		height: 100%;
	}
}

.svg-open,.svg-close {
	width: 14px;
	height: 14px;
	display:inline-block;
	vertical-align: middle;
	margin-right: 10px;
	background-size:100% auto;
	background-repeat:no-repeat;
}

.margin-bottom-30 {
	margin-bottom: 30px;
}

.margin-bottom-20 {
	margin-bottom: 20px;
}